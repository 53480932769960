import axios from "axios";
import { AUTH_TOKEN_PATH, API_MERCHANT_ROOT, toastTime } from "./constant";
import { actionCreator } from "../components/layouts/Header";
import store from '../redux/store';
import { toast } from "react-toastify";

let cancelTokenSource = axios.CancelToken.source();

const getJWT = () => {
  const jwt = localStorage.getItem(AUTH_TOKEN_PATH);
  return jwt;
};

export const GetJwt = getJWT;

const MerchantAxiosInstance = axios.create({
  baseURL: API_MERCHANT_ROOT,
  headers: {
    // "Authorization": getJWT(),
    "Content-Type": "application/json",
    'Accept': '*/*',
    'Accept-Encoding': 'gzip, deflate, br',
    'Connection': 'keep-alive',
    'Content-Length': '0'
    // "Content-Type": "application/json",

  },
});

export const isTokenExpired = (tokenExpiry) => {
  const tokenExpiryInMs = tokenExpiry * 1000;
  return tokenExpiryInMs && Date.now() > tokenExpiryInMs;
};

export const decodeJwtDetails = (merchantToken) => {  
  return JSON.parse(window.atob(merchantToken.split('.')[1]))
}

export const verifyMerchantAuthToken = () => {
  const isLoggedIn = store.getState().auth.isLoggedIn;
  const adminJwtToken = store.getState().auth.token;
  const hasLoggedOut = store.getState().auth.hasLoggedOut;

  if(isLoggedIn) {
    const adminJwt = adminJwtToken || GetJwt();

    if (adminJwt) {
      const adminJwtDetails = decodeJwtDetails(adminJwt);
      const tokenExpiry = adminJwtDetails.exp;
      
      if (isTokenExpired(tokenExpiry) && !hasLoggedOut) {
        cancelTokenSource.cancel('Token expired, cancelling all pending requests.');
        cancelTokenSource = axios.CancelToken.source();
        
        toast.error('The session has expired. Please login.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: toastTime,
        })
        const autoLogout = true;
        store.dispatch(actionCreator.logout(autoLogout));
      }
    }
  }
}

MerchantAxiosInstance.interceptors.request.use((config) => {
  config.headers["Authorization"] = getJWT();
  config.cancelToken = cancelTokenSource.token;
  return config;
});

MerchantAxiosInstance.interceptors.response.use((response) => {
  verifyMerchantAuthToken();
  return response;
}, (error) => {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      verifyMerchantAuthToken();
    }
  verifyMerchantAuthToken();
  return Promise.reject(error);
});

export { MerchantAxiosInstance };
